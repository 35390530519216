import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { take } from 'rxjs';
import { GeneralConfig } from 'src/app/interfaces/common';
import { IdleTimerComponent } from 'src/app/service/idle-timer/idle-timer.component';

@Component({
  selector: 'app-camera-stream',
  templateUrl: './camera-stream.component.html',
  styleUrls: ['./camera-stream.component.scss']
})
export class CameraStreamComponent implements OnInit {

  
  @ViewChild('videoRef') videoRef:ElementRef | null = null;
  public dateString = "";
  public timeString = "";
  public videoWidth: string = "200%";
  public videoHeight: string = "200%";
  public videoResolutionX: number = 3840;
  public videoResolutionY: number = 2160;
  public videoOffsetX: string = "0%";
  public videoOffsetY: string = "0%";
  public videoHorizontalCroppedAlingment: string = "center";
  public videoVerticalCroppedAlingment: string = "center";

  @Input() displayLogos: boolean = false;
  @Input() isFramed: boolean = false;

  constructor(private idleTimerService: IdleTimerComponent,private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.httpClient.get("./assets/config.json").pipe(take(1)).subscribe(res => {
      //console.log("sub renderer");
      if(res != null){
        this.videoWidth = (res as GeneralConfig).videoHeight;
        this.videoHeight = (res as GeneralConfig).videoHeight;
        this.videoOffsetX = (res as GeneralConfig).videoOffsetX;
        this.videoOffsetY = (res as GeneralConfig).videoOffsetY;
        this.videoHorizontalCroppedAlingment = (res as GeneralConfig).videoHorizontalCroppedAlingment;
        this.videoVerticalCroppedAlingment = (res as GeneralConfig).videoVerticalCroppedAlingment == 'top' ? 'flex-start': (res as GeneralConfig).videoVerticalCroppedAlingment == 'bottom' ? 'flex-end': (res as GeneralConfig).videoVerticalCroppedAlingment;
      }
    });
    let now = new Date();
    //this.dateString = now.toISOString().slice(0,10).replace(/-/g,"/");
    //this.timeString = now.toLocaleTimeString("en-US",{hour: '2-digit', minute: '2-digit', second: '2-digit'}).slice(0,5)+
    //  now.toLocaleTimeString("en-US",{hour: '2-digit', minute: '2-digit', second: '2-digit'}).slice(8);
    this.dateString = "DATE "+ now.toLocaleString('de-DE', {day: '2-digit'})+ "." + now.toLocaleString('de-DE', {month: 'short'});
    this.timeString = "TIME "+now.toLocaleTimeString("de-DE",{hour: '2-digit', minute: '2-digit', second: '2-digit'}).slice(0,5);
    this.idleTimerService.stopTimer();

    //navigator.mediaDevices.enumerateDevices().then(this.gotDevices);
  }

  gotDevices(mediaDevices: MediaDeviceInfo[]) {
    mediaDevices.forEach((mediaDevice: MediaDeviceInfo) => {
      if (mediaDevice.kind === 'videoinput') {
        console.log(mediaDevice.label);
        console.log(mediaDevice.deviceId);
      }
    });
  }
  
  public pauseVideo(): void{
    if(!this.videoRef)
      return;
    let video = this.videoRef.nativeElement as HTMLVideoElement;
    video.pause();
  }

  public playVideo(): void{
    if(!this.videoRef)
      return;
    let video = this.videoRef.nativeElement as HTMLVideoElement;
    video.play();
  }

  ngAfterViewInit(): void {
    if(!this.videoRef)
      return;
    let video = this.videoRef.nativeElement as HTMLVideoElement;
    this.httpClient.get("./assets/config.json").pipe(take(1)).subscribe(res => {
      if(res != null){
        this.videoResolutionX = (res as GeneralConfig).videoResolutionX;
        this.videoResolutionY = (res as GeneralConfig).videoResolutionY;

        navigator.mediaDevices.getUserMedia( {
          video : {
            //deviceId: "f94faf04eaddfb42ac80f13eb22d7b7d0c1d629814829cd39dbfe7d7b08896ae", //hp testdevice
            //deviceId: "1dc8aa369f1a261223622b759f9c807b2bad392f711ffdb8bc14fe269b2a6d20", //logitech testdevice
            width: { ideal: this.videoResolutionX }, //try max res for chosen hp webcam
            height: { ideal: this.videoResolutionY } //try max res for chosen hp webcam
          },
        }).then((stream) => {
          video.srcObject = stream;
        }).catch(e => {
          console.warn("warning ",e);
        });
      }
    });
    setInterval(() => {         
      let now = new Date();
      this.dateString = "DATE "+ now.toLocaleString('de-DE', {day: '2-digit'})+ "." + now.toLocaleString('de-DE', {month: 'short'});
      this.timeString = "TIME "+now.toLocaleTimeString("de-DE",{hour: '2-digit', minute: '2-digit', second: '2-digit'}).slice(0,5);
    }, 5000);
  }

}
