<div class="background">
    <div class="main-container">
        <div class="main-container__videoContainer" (click)="clickedVideo()">
            <video
                muted
                #videoRef
                (timeupdate)="videoUpdate($event)"
            ><source
                src="assets/videos/video.mp4"
                type="video/mp4"
            /></video>
            <div class="video-controls">
                <div class="video-controls__progress" *ngIf="!this.videoIsRunning" >
                    <input class="video-controls__progress__seekbar" type="range" #seekbarRef value="0" (input)="clickedSeekbar($event)">
                </div>
                <div class="video-controls__buttons" *ngIf="!this.videoIsRunning">
                    <div class="fas fa-backward" (click)="clickedVideoBack()"></div>
                    <div class="fas fa-play"></div>
                    <div class="fas fa-forward" (click)="clickedVideoForward()"></div>
                </div>
            </div>
        </div>
    </div>
    <app-bottom-bar [backButton]="true" [infoText]="{text: 'video:bottom-bar__info-popup__text' | translate}"></app-bottom-bar>
</div>