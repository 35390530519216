import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subject, take, takeUntil } from 'rxjs';
import { PopupContent } from 'src/app/interfaces/common';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit, OnDestroy {

  private readonly destroyed$ = new Subject<void>();
  popupContent: PopupContent | undefined;
  @ViewChild('videoRef') videoRef:ElementRef | null = null;
  @ViewChild('seekbarRef') seekbarRef:ElementRef | null = null;
  videoIsRunning: boolean = false;

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
  }

  videoUpdate(_e: Event){
    let seekbar = this.seekbarRef?.nativeElement as HTMLInputElement;
    let video = this.videoRef?.nativeElement as HTMLVideoElement;
    if(video && seekbar)
      seekbar.value = ''+((100 / video.duration) * video.currentTime);
  }

  clickedSeekbar(e: Event): void{
    let seekbar = this.seekbarRef?.nativeElement as HTMLInputElement;
    let video = this.videoRef?.nativeElement as HTMLVideoElement;
    if(video && seekbar){
      video.currentTime = video.duration * (Number(seekbar.value) / 100);
    }
      
  }

  clickedVideo(): void {
    let video = this.videoRef?.nativeElement as HTMLVideoElement;
    if(!video)
      return;
    if(video.paused){
      video.play();
    } else {
      video.pause();
    }
    this.videoIsRunning = !video.paused;
  }

  clickedVideoBack(): void{
    let video = this.videoRef?.nativeElement as HTMLVideoElement;
    if(!video)
      return;
    video.currentTime = video.currentTime - (video.duration/10);
    setTimeout(() => {
      video.pause();
      this.videoIsRunning = !video.paused;
    }, 50);
  }

  clickedVideoForward(): void{
    let video = this.videoRef?.nativeElement as HTMLVideoElement;
    if(!video)
      return;
    video.currentTime = video.currentTime + (video.duration/10);
    setTimeout(() => {
      video.pause();
      this.videoIsRunning = !video.paused;
    }, 50);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
