import { Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { Subject, take } from 'rxjs';
import { PopupContent, QuizAnswerState, QuizInfo, QuizRelation, QuizResultText, QuizUserInfo } from 'src/app/interfaces/common';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { animate, style, transition, trigger } from '@angular/animations';
import { Router } from '@angular/router';
import { JsonLoaderComponent } from 'src/app/service/json-loader/json-loader.component';

export const fadeInOutTimeout = 200;
export let fadeInOut = trigger('fadeInOut', [
  transition('void => left', [style({ opacity: '0', transform: 'translateX(-100%)' }), animate(0)]),
  transition('void => right', [style({ opacity: '0', transform: 'translateX(100%)' }), animate(0)]),
  transition('* => void', [animate(fadeInOutTimeout, style({ opacity: '0' }))]),
  transition('* => left', [
    animate(fadeInOutTimeout, style({ opacity: '0', transform: 'translateX(100%)' })),
    animate(fadeInOutTimeout, style({ opacity: '0', transform: 'translateX(-100%)' })),
    animate(fadeInOutTimeout, style({ opacity: '1', transform: 'translateX(0%)' })),
  ]),
  transition('* => right', [
    animate(fadeInOutTimeout, style({ opacity: '0', transform: 'translateX(-100%)' })),
    animate(fadeInOutTimeout, style({ opacity: '0', transform: 'translateX(100%)' })),
    animate(fadeInOutTimeout, style({ opacity: '1', transform: 'translateX(0%)' })),
  ]),
]);

export let correctAnswerAnimation = trigger('correctAnswerAnimation', [
  transition('* => void', [
    animate(0),
    animate(400, style({ opacity: '0', transform: 'translateX(100%) scale(0)' })),
  ])
]);

export const timePerShake = 30;
export let wrongAnswerAnimation = trigger('wrongAnswerAnimation', [
  transition('* => true', [
    animate(0),
    animate(timePerShake, style({ transform: 'rotate(-5deg)' })),
    animate(timePerShake, style({ transform: 'rotate(0deg)' })),
    animate(timePerShake, style({ transform: 'rotate(5deg)' })),
    animate(timePerShake, style({ transform: 'rotate(0deg)' })),
    animate(timePerShake, style({ transform: 'rotate(-5deg)' }))
  ])
]);


@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
  animations: [fadeInOut, correctAnswerAnimation, wrongAnswerAnimation],
})
export class QuizComponent implements OnInit {
  private readonly destroyed$ = new Subject<void>();
  isIntro: boolean = true;
  isQuizCompleted: boolean = false;
  popupContent: PopupContent | undefined;
  dragableImagePath: string = "assets/images/quiz/apeImagesDragable/";
  infoImagePath: string = "assets/images/quiz/infoBox/";

  @ViewChild('scrollContainerLeft') private scrollContainerLeft: ElementRef | undefined;
  @ViewChild('scrollContainerRight') private scrollContainerRight: ElementRef | undefined;
  @ViewChild('scrollContainerBottom') private scrollContainerBottom: ElementRef | undefined;
  scrollDistance: number = 200;

  draggedElement: HTMLElement | undefined;
  startx: any;
  starty: any;

  //loaded from json config
  quizRelations: QuizRelation[] = [];

  //loaded from json config
  quizInfos: QuizInfo[] = [];


  quizInfoIndex: number = 0;

  QuizAnswerState = QuizAnswerState;
  checkAnswerTimer: any;
  answerState: QuizAnswerState = QuizAnswerState.idle;
  quizCheckingSTime: number = 1;
  quizLangParam: QuizUserInfo = {quizUserTries: 0, quizUserAverage: null , quizUserAverageDeString: null};
  quizSelectionAmount: number = 7;

  quizRelationDropped: QuizRelation | undefined;

  quizRelationDone: QuizRelation[] = [];

  isScrollbarLeftBottom = false;
  isScrollbarLeftTop = true;
  isScrollbarRightBottom = false;
  isScrollbarRightTop = true;
  bottomContainerSlideTo: string | null = "right";
  wrongAnswerAnimationTrigger:boolean = false;
  quizResultTexts: QuizResultText[] = [];

  infoBoxBottomAutoSlideIntervall: any;

  constructor(private jsonLoader: JsonLoaderComponent, private router: Router) { }

  ngOnInit(): void {
    this.initQuiz();
  }

  initQuiz(): void{
    let quizConfig = this.jsonLoader.getQuizConfig();
    if(quizConfig != null){
      let allRelations = quizConfig.quizRelations;
      this.quizSelectionAmount = quizConfig.quizSelectionAmount;
      this.quizRelations = this.selectRandomElementsFromArray([...allRelations], quizConfig.quizSelectionAmount);
      this.quizInfos = quizConfig.quizInfos;
      this.jsonLoader.getAverageTries().pipe(take(1)).subscribe(res => {
        this.quizLangParam.quizUserAverage = res.average;
      });
      this.quizLangParam.quizUserTries = 0;
      this.quizLangParam.quizUserAverageDeString = "";
      this.quizResultTexts = quizConfig.quizResultTexts;
      this.isIntro = true;
      this.isQuizCompleted = false;
      this.quizRelationDropped = undefined;
      this.quizRelationDone = [];
      this.quizInfoIndex = 0;
    } else {
      this.router.navigate(['/']);
    }
    
    setTimeout(() => {
      if(this.isIntro)
        this.clickScrollBottomRight()
    },1000);
    this.infoBoxBottomAutoSlideIntervall = setInterval(() => this.clickScrollBottomRight(),4000)
  }

  selectRandomElementsFromArray(array: any, length: number): any[]{
    let allRelations = [...array];
    let randomQuizRelations: QuizRelation[] = [];
    for (let i = 0; i < length; i++) {
      if(allRelations.length < 1)
        break;
      let randomIndex = Math.floor(Math.random() * allRelations.length);
      randomQuizRelations.push(allRelations[randomIndex]);
      allRelations.splice(randomIndex, 1);
    }
    return [...randomQuizRelations];
  }

  getInfoIndexString(): string{
    return this.quizLangParam.quizUserTries < 10 ? '0' +(this.quizLangParam.quizUserTries) : (this.quizLangParam.quizUserTries) + '';
  }
  getInfoLengthString(): string{
    return this.quizSelectionAmount < 10 ? '0' +(this.quizSelectionAmount) : (this.quizSelectionAmount) + '';
  }

  clickEndIntro(): void{
    this.isIntro = false;
    if(this.infoBoxBottomAutoSlideIntervall != null)
      clearInterval(this.infoBoxBottomAutoSlideIntervall);
  }

  clickScrollLeftUp(): void{
    if(this.scrollContainerLeft)
      try {
        this.scrollContainerLeft.nativeElement.scrollTop -= this.scrollDistance;
      } catch(err) { console.warn('scroll warn ',err); }
  }

  clickScrollLeftDown(): void{
    if(this.scrollContainerLeft)
      try {
        this.scrollContainerLeft.nativeElement.scrollTop += this.scrollDistance;
      } catch(err) { console.warn('scroll warn ',err); }
  }

  clickScrollRightUp(): void{
    if(this.scrollContainerRight)
      try {
        this.scrollContainerRight.nativeElement.scrollTop -= this.scrollDistance;
      } catch(err) { console.warn('scroll warn ',err); }
  }

  clickScrollRightDown(): void{
    if(this.scrollContainerRight)
      try {
        this.scrollContainerRight.nativeElement.scrollTop += this.scrollDistance;
      } catch(err) { console.warn('scroll warn ',err); }
  }

  clickScrollBottomLeft(): void{
    this.bottomContainerSlideTo = null;
    setTimeout(() => {
      this.bottomContainerSlideTo = "left";
    },1);
    setTimeout(() => {
      if(this.quizInfoIndex > 0)
        this.quizInfoIndex--;
      else
        this.quizInfoIndex = this.quizInfos.length-1;
    
      if(this.scrollContainerBottom?.nativeElement){
        let bottomScrollContainer = this.scrollContainerBottom.nativeElement as HTMLElement;
        bottomScrollContainer.scrollTop = 0;
      }
    },fadeInOutTimeout);
  }

  clickScrollBottomRight(): void{
    this.bottomContainerSlideTo = null;
    setTimeout(() => {
      this.bottomContainerSlideTo = "right";
    },1);
    setTimeout(() => {
      if(this.quizInfoIndex + 1 > this.quizInfos.length-1)
        this.quizInfoIndex = 0;
      else
        this.quizInfoIndex++;
      if(this.scrollContainerBottom?.nativeElement){
        let bottomScrollContainer = this.scrollContainerBottom.nativeElement as HTMLElement;
        bottomScrollContainer.scrollTop = 0;
      }
    },fadeInOutTimeout);
   
  }

  leftScrollBarScrolled(_e: Event): void{
    this.isScrollbarLeftTop = this.isUserNearTop(this.scrollContainerLeft?.nativeElement);
    this.isScrollbarLeftBottom = this.isUserNearBottom(this.scrollContainerLeft?.nativeElement);
  }

  leftDragableClick(quizRelation: QuizRelation, index: number): void{
    if(this.quizRelationDropped == null){
      this.quizRelationDropped = quizRelation;
      this.quizRelations.splice(index, 1);
    } else {
      this.quizRelations.push({...this.quizRelationDropped});
      this.quizRelationDropped = quizRelation;
      this.quizRelations.splice(index, 1);
    }
    setTimeout(() => this.checkBothScrolls(), 500);
  }

  rightScrollBarScrolled(_e: Event): void{
    this.isScrollbarRightTop = this.isUserNearTop(this.scrollContainerRight?.nativeElement);
    this.isScrollbarRightBottom = this.isUserNearBottom(this.scrollContainerRight?.nativeElement);
  }

  checkBothScrolls():void{
    this.isScrollbarLeftTop = this.isUserNearTop(this.scrollContainerLeft?.nativeElement);
    this.isScrollbarLeftBottom = this.isUserNearBottom(this.scrollContainerLeft?.nativeElement);
    this.isScrollbarRightTop = this.isUserNearTop(this.scrollContainerRight?.nativeElement);
    this.isScrollbarRightBottom = this.isUserNearBottom(this.scrollContainerRight?.nativeElement);
  }

  rightScrollbarToTop(): void{
    if(this.scrollContainerRight?.nativeElement){
      let  scrollContainer: HTMLElement = this.scrollContainerRight?.nativeElement;
      scrollContainer.scrollTop = 0;
    }
  }

  isUserNearTop(scrollContainer: HTMLElement): boolean {
    const threshold = 5;
    return scrollContainer.scrollTop  <= threshold;
  }

  isUserNearBottom(scrollContainer: HTMLElement): boolean {
    const threshold = 5;
    const position = scrollContainer.scrollTop + scrollContainer.offsetHeight;
    const height = scrollContainer.scrollHeight;
    return position > height - threshold;
  }

  clickCheckSelection(): void{
    if(this.checkAnswerTimer)
      clearTimeout(this.checkAnswerTimer);

    if(this.quizRelationDropped?.associatedInfoIndex == this.quizInfos[this.quizInfoIndex].infoIndex){  // && correct selection
      this.answerState = QuizAnswerState.right;
      this.checkAnswerTimer = setTimeout(() => {
        this.checkSelection(true);
      }, this.quizCheckingSTime * 1000);
    } else if(this.quizRelationDropped) {
      this.answerState = QuizAnswerState.wrong;
      this.wrongAnswerAnimationTrigger = true;
      setTimeout(() => this.wrongAnswerAnimationTrigger = false, 500);
      this.checkAnswerTimer = setTimeout(() => {
        this.checkSelection(false);
      }, this.quizCheckingSTime * 1000);
    } else {
      return;
    }
  }

  checkSelection(isCorrect: boolean): void{
    this.quizLangParam.quizUserTries++;
    if(this.quizRelationDropped && isCorrect){
      this.quizRelationDone.unshift({...this.quizRelationDropped});
      this.quizRelationDropped = undefined;
      this.rightScrollbarToTop();
    } //else if(this.quizRelationDropped)  {
     //this.quizRelations.push({...this.quizRelationDropped});
    //}
    //this.quizRelationDropped = undefined;
    this.answerState = QuizAnswerState.idle;
    if(this.quizRelations.length == 0 && this.quizRelationDropped == null){
      this.setQuizComplete();
    } 
    this.checkAnswerTimer = null;
    setTimeout(() => this.checkBothScrolls(), 500);
  }

  setQuizComplete(){
    if(this.quizLangParam.quizUserAverage != null){
      this.jsonLoader.setTries(this.quizLangParam.quizUserTries).pipe(take(1)).subscribe(res => {
        this.quizLangParam.quizUserAverage = res.average;
        this.quizLangParam.quizUserAverageDeString = (res.average+'').replace('.',',');
        this.isQuizCompleted = true;
      });
    }
  }

  clickResetQuiz(): void{
    this.isQuizCompleted = false;
    this.quizLangParam.quizUserTries = 0;
    this.quizLangParam.quizUserAverage = 0;
    if(this.checkAnswerTimer)
      clearTimeout(this.checkAnswerTimer);
    this.initQuiz();
  }
  
  drop(event: CdkDragDrop<string[]>): void{
    if(this.quizRelationDropped == null){
      this.quizRelationDropped = this.quizRelations[event.previousIndex];
      this.quizRelations.splice(event.previousIndex, 1);
    } else {
      this.quizRelations.push({...this.quizRelationDropped});
      this.quizRelationDropped = this.quizRelations[event.previousIndex];
      this.quizRelations.splice(event.previousIndex, 1);
    }
    setTimeout(() => this.checkBothScrolls(), 500);
  }
  
  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  getResultText(): string{
    let smallestIndex = -1;
    for (let i = 0; i < this.quizResultTexts.length; i++) {
      if(this.quizLangParam.quizUserTries >= this.quizResultTexts[i].aboveOrEqualTries){
        smallestIndex = i;
      }
    }
    if(smallestIndex >= 0){
      return this.quizResultTexts[smallestIndex].text;
    }
    return this.quizResultTexts[0].text;
  }

  getResultTitle(): string{
    let smallestIndex = -1;
    for (let i = 0; i < this.quizResultTexts.length; i++) {
      if(this.quizLangParam.quizUserTries >= this.quizResultTexts[i].aboveOrEqualTries){
        smallestIndex = i;
      }
    }
    if(smallestIndex >= 0){
      return this.quizResultTexts[smallestIndex].title;
    }
    return this.quizResultTexts[0].title;
  }

}
