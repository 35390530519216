import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-idle-timer',
  templateUrl: './idle-timer.component.html',
  styleUrls: ['./idle-timer.component.scss']
})
export class IdleTimerComponent implements OnInit {

  idleTimer = 60;
  timeout: any;

  constructor(private elementRef:ElementRef, private renderer:Renderer2, private router: Router) {}

  ngOnInit(): void {}

  public init(): void{
    this.renderer.listen(this.elementRef.nativeElement, 'click', (event: Event) => { 
      //console.log("renderer reset");
      this.resetTimer(event);
    });
  }

  public setIdleTimer(newTimer: number){
    //console.log("set idleTimer");
    this.idleTimer = newTimer;
  }

  public startTimer(): void{
    //console.log("startTimer ",this.idleTimer);
    if(this.timeout != null)
      clearTimeout(this.timeout);
    this.timeout = setTimeout(() => this.timerTriggered(), this.idleTimer * 1000);
  }

  public stopTimer(): void{
    //console.log("stopTimer");
    if(this.timeout != null)
      clearTimeout(this.timeout);
    this.timeout = null;
  }

  public resetTimer(e: Event): void{
    //console.log("resetTimer event", e);
    this.stopTimer();
    this.startTimer();
  }

  public timerTriggered(): void{
    //console.log("TIMER TRIGGERED")
    this.router.navigate(['/idle-screen']);
  }

}
