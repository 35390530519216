import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import {map, Observable, of, take} from 'rxjs';
import { GeneralConfig, QuizConfig } from 'src/app/interfaces/common';
import { IdleTimerComponent } from '../idle-timer/idle-timer.component';

export interface ScoreData{
  average: number,
  count: number
}

@Component({
  selector: 'app-json-loader',
  templateUrl: './json-loader.component.html',
  styleUrls: ['./json-loader.component.scss']
})
export class JsonLoaderComponent implements OnInit {

  generalConfig: GeneralConfig | undefined;
  quizConfig: QuizConfig | undefined;

  constructor(private httpClient: HttpClient, private idleTimer: IdleTimerComponent) { }

  ngOnInit(): void {
  }

  public loadJsonFiles(){
    this.httpClient.get("./assets/config.json").pipe(take(1)).subscribe(res => {
      if(res != null){
        this.generalConfig = (res as GeneralConfig);
        this.idleTimer.setIdleTimer(this.generalConfig.idleTimer)
      }
    });
    this.httpClient.get("./assets/quizConfig.json").pipe(take(1)).subscribe(res => {
      if(res != null)
        this.quizConfig = (res as QuizConfig);
    });
  }

  public getQuizConfig(): QuizConfig | undefined{
    return this.quizConfig;
  }

  public setTries(num: number): Observable<ScoreData>{
    if(this.generalConfig?.useHttpHighScore === true){
      return this.httpClient.post(this.generalConfig.httpConnectionURL,{score: num}, {
        headers: {
          'x-auth': this.generalConfig.httpConnectionAuth
        }
      }).pipe(take(1), map(res => res as ScoreData));
    }
    this.checkAverageUserSolutionOverride();
    return of(Number(localStorage.getItem('storedOldTries'))).pipe(
        take(1),
        map( res => {
          let triesAmountOld = localStorage.getItem('storedOldTriesAmount') || '0';
          localStorage.setItem('storedOldTriesAmount',(Number(triesAmountOld)+1)+'');
          localStorage.setItem('storedOldTries', ( res + num )+'');
          return {
            average: Math.round(((res + num) / (Number(triesAmountOld ) + 1) )*10)/10,
            count: Number(triesAmountOld) + 1
          };
        })
    );
  }

  public getAverageTries():Observable<ScoreData>{
    if(this.generalConfig?.useHttpHighScore === true){
      return this.httpClient.get(this.generalConfig.httpConnectionURL, {
        headers: {
          'x-auth': this.generalConfig.httpConnectionAuth
        }
      }).pipe(take(1), map(res => res as ScoreData));
    }
    this.checkAverageUserSolutionOverride();
    return of(Number(localStorage.getItem('storedOldTriesAmount')) === 0 ?
      {
        count: 0,
        average: 0
      } : {
        average: Number(localStorage.getItem('storedOldTries')) / Number(localStorage.getItem('storedOldTriesAmount')),
        count: Number(localStorage.getItem('storedOldTriesAmount'))
      }
    );
  }

  checkAverageUserSolutionOverride(){
    if(!localStorage.getItem('storedOldTries') && !localStorage.getItem('storedOldTriesAmount') && this.quizConfig?.quizAverageUserSolution != null && this.quizConfig.quizAverageUserSolution != 0){
      localStorage.setItem('storedOldTries', this.quizConfig.quizAverageUserSolution+'');
      localStorage.setItem('storedOldTriesAmount', '1');
    } else if(!localStorage.getItem('storedOldTries') || !localStorage.getItem('storedOldTriesAmount') || Number(localStorage.getItem('storedOldTries')) > Number.MAX_VALUE -5000){
      localStorage.setItem('storedOldTries', '0');
      localStorage.setItem('storedOldTriesAmount', '0');
    }
  }

}
