<div class="background">
    <div class="main-container">
        <h1 class="main-container__title">{{'home:main-container__title' | translate}}</h1>
        <div class="main-container__description">
            <p class="main-container__description__left-column">{{'home:main-container__description__left-column' | translate}}</p>
            <p class="main-container__description__right-column">{{'home:main-container__description__rigth-column' | translate}}</p>
        </div>
        <div class="main-container__selection">
            <div class="main-container__selection__box main-container__selection__video" routerLink="/video">
                <img class="preview-container" [src]="'assets/images/home-video.jpg'" />
                <p>{{'home:main-container__selection__box-video' | translate}}</p>
                <div class="finger-icon fas fa-hand-pointer"></div>
            </div>
            <div class="main-container__selection__box main-container__selection__liveCam" routerLink="/live-cam">
                <app-camera-stream class="preview-container"></app-camera-stream>
                <p>{{'home:main-container__selection__box-live-cam' | translate}}</p>
                <div class="finger-icon fas fa-hand-pointer"></div>
                <div class="category-icon fas fa-user-alt"></div>
            </div>
            <div class="main-container__selection__box main-container__selection__quiz" routerLink="/quiz">
                <img class="preview-container" [src]="'assets/images/home-quiz.jpg'" />
                <p>{{'home:main-container__selection__box-quiz' | translate}}</p>
                <div class="finger-icon fas fa-hand-pointer"></div>
            </div>
        </div>
    </div>
    <app-bottom-bar></app-bottom-bar>
</div>