<div class="background">
    <div class="main-container">
        <div class="main-container__videoContainer" [ngClass]="{'camOpenContainer': isCamOpen}">
            <app-camera-stream [isFramed]="!isCamOpen" [displayLogos]="true" [ngClass]="{'camOpen': isCamOpen}" #cameraStream></app-camera-stream>
        </div>
    </div>
    <div *ngIf="!isCamOpen" class="openCam-container">
        <img class="camera-icon" src="/assets/images/button-foto.png" (click)="toggleCam()">
    </div>
    <div *ngIf="isCamOpen" class="closeCam-container" (click)="toggleCam()">
        <img class="close-icon" src="/assets/images/button-close-video.png">
    </div>
    <app-bottom-bar *ngIf="!isCamOpen" [backButton]="true" [infoText]="{text: 'live-cam:bottom-bar__info-popup__text' | translate}"></app-bottom-bar>
</div>