import { Component, OnDestroy, OnInit } from '@angular/core';
import { IdleTimerComponent } from 'src/app/service/idle-timer/idle-timer.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(private idleTimerService: IdleTimerComponent) { }

  ngOnInit(): void {
    this.idleTimerService.startTimer();
  }


}
