import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { IdleTimerComponent } from './service/idle-timer/idle-timer.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Museum Fotofalle';

  constructor(translate: TranslateService, idleTimerService: IdleTimerComponent) {
    translate.setDefaultLang('en');
    translate.use('de');
    idleTimerService.init();
  }

}
