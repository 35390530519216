export type GeneralConfig = {
    idleTimer: number;
    videoWidth: string;
    videoHeight: string;
    videoOffsetX: string;
    videoOffsetY: string;
    videoResolutionX: number;
    videoResolutionY: number;
    videoHorizontalCroppedAlingment: string;
    videoVerticalCroppedAlingment: string;
    useHttpHighScore: boolean;
    httpConnectionAuth: string;
    httpConnectionURL: string;
};

export type PopupContent = {
  text: string;
};


export type QuizRelation = {
  dragableImage: string;
  associatedInfoIndex: number;
  nameLangString: string;
}

export type QuizInfo = {
  infoIndex: number;
  infoImages: InfoImage[];
  infoLangString: string;
}

export type InfoImage = {
  image: string;
  titleLangString: string;
}

export enum QuizAnswerState {
  idle, right, wrong
}

export type QuizConfig = {
  quizSelectionAmount: number;
  quizAverageUserSolution: number | null;
  quizResultTexts: QuizResultText[];
  quizRelations: QuizRelation[];
  quizInfos: QuizInfo[];
}

export type QuizUserInfo = {
  quizUserTries: number;
  quizUserAverage: number | null;
  quizUserAverageDeString: string | null;
}

export type QuizResultText = {
  aboveOrEqualTries: number;
  title: string
  text: string;
}
