import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IdleTimerComponent } from 'src/app/service/idle-timer/idle-timer.component';
import { JsonLoaderComponent } from 'src/app/service/json-loader/json-loader.component';

@Component({
  selector: 'app-idle-screen',
  templateUrl: './idle-screen.component.html',
  styleUrls: ['./idle-screen.component.scss']
})
export class IdleScreenComponent implements OnInit {

  @ViewChild('videoRef') videoRef:ElementRef | null = null;
  public dateString = "";
  public timeString = "";

  constructor(private router: Router, private idleTimerService: IdleTimerComponent, private jsonLoader: JsonLoaderComponent) { }

  ngOnInit(): void {
    this.jsonLoader.loadJsonFiles();
    this.idleTimerService.stopTimer();
  }

  public clickedOverlay(){
    this.router.navigate(['/home']);
  }
  

}
