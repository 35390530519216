<div *ngIf="infoText != null && showPopup" class="popup-container" (click)="clickHideInfo()">
    <div class="popup-container__box" >
        <div class="popup-container__box__close-icon">
            <img src="assets/images/button-close-top.png" />
        </div>
        <div class="popup-container__box__container" [innerHTML]="infoText.text">
        </div>
    </div>
</div>
    <div class="bottom-bar__languages">
        <div class="bottom-bar__languages__de" (click)="clickDeLang()">
            <img *ngIf="getCurrentLang() == 'de'" src="assets/images/de-aktiv.png"/>
            <img *ngIf="getCurrentLang() == 'en'" src="assets/images/de-inaktiv.png"/>
        </div>
        <div class="bottom-bar__languages__en" (click)="clickEnLang()">
            <img *ngIf="getCurrentLang() == 'en'" src="assets/images/en-aktiv.png"/>
            <img *ngIf="getCurrentLang() == 'de'" src="assets/images/en-inaktiv.png"/>
        </div>
    </div>
    <div class="bottom-bar__infoAndBack-container">
        <div *ngIf="infoText != null" class="bottom-bar__infoAndBack-container__info" (click)="clickShowInfo()"><img src="assets/images/button-info.png"/></div>
        <div *ngIf="backButton" class="bottom-bar__infoAndBack-container__back" routerLink="/home"><img src="assets/images/button-zurueck.png"/></div>
    </div>