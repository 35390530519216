<div class="main-container">
    <div 
        class="video-container"
        [ngStyle]="{
            'justify-content': videoHorizontalCroppedAlingment, 
            'align-items': videoVerticalCroppedAlingment
        }" 
    >
        <video 
            [ngStyle]="{
                'width': videoWidth, 
                'height': videoHeight,
                'margin-left': videoOffsetX, 
                'margin-top': videoOffsetY
            }" 
            #videoRef 
            autoplay
        ></video>
    </div>
    <img *ngIf="displayLogos" [ngClass]="{'framed': isFramed}" class="muesum-logo" src="/assets/images/logo-museum-koenig.png"/>
    <div *ngIf="displayLogos" [ngClass]="{'framed': isFramed}" class="together-container">
        <img class="together-logo" src="/assets/images/video-overlay.png"/>
        <span class="together-container__text-top">{{'liveCam:together-container__text-top' | translate}}</span>
        <span class="together-container__text-bottom">{{'liveCam:together-container__text-bottom' | translate}}</span>
    </div>
    <div class="bottom-bar" [ngClass]="{'framed': isFramed}">
        <div class="bottom-bar__info">
            <span>CAM Canopy 2</span><span>TEMP 22°C &nbsp; HUM 52%</span><span>{{dateString}} &nbsp; {{timeString}}</span>
        </div>
    </div>
</div>
