import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { IdleScreenComponent } from './pages/idle-screen/idle-screen.component';
import { VideoComponent } from './pages/video/video.component';
import { LiveCamComponent } from './pages/live-cam/live-cam.component';
import { QuizComponent } from './pages/quiz/quiz.component';
import { BottomBarComponent } from './components/bottom-bar/bottom-bar.component';
import { IdleTimerComponent } from './service/idle-timer/idle-timer.component';
import { CameraStreamComponent } from './components/camera-stream/camera-stream.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { JsonLoaderComponent } from './service/json-loader/json-loader.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    IdleScreenComponent,
    VideoComponent,
    LiveCamComponent,
    QuizComponent,
    BottomBarComponent,
    IdleTimerComponent,
    CameraStreamComponent,
    JsonLoaderComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    DragDropModule,
    TranslateModule.forRoot({
      defaultLanguage: 'de',
      loader: {
          provide: TranslateLoader,
          useFactory: (createTranslateLoader),
          deps: [HttpClient]
      }
  }),
    AppRoutingModule
  ],
  providers: [IdleTimerComponent, JsonLoaderComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
