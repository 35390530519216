import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PopupContent } from 'src/app/interfaces/common';

@Component({
  selector: 'app-bottom-bar',
  templateUrl: './bottom-bar.component.html',
  styleUrls: ['./bottom-bar.component.scss']
})
export class BottomBarComponent implements OnInit {

  @Input() backButton: boolean = false;
  @Input() infoText: PopupContent | undefined;
  showPopup = false;

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {

  }

  public clickDeLang(): void{
    this.translate.use('de');
  }

  public clickEnLang(): void{
    this.translate.use('en');
  }

  public getCurrentLang(): string{
    return this.translate.currentLang;
  }

  public clickShowInfo(): void{
    this.showPopup = !this.showPopup;
  }

  public clickHideInfo(): void{
    this.showPopup = false;
  }

}
