<div class="background" *ngIf="quizInfos.length > 0">
    <div class="left-container">
        <h1>{{'quiz:center-container__left-title' | translate}} {{" – "+quizRelations.length+" – "}}</h1>
        <div class="left-container__drag-container">
            <div class="left-container__drag-container__up-button"><div *ngIf="quizRelations.length > 3 && !isScrollbarLeftTop" class="chevron-icon fas fa-chevron-up" (click)="clickScrollLeftUp()"></div></div>
            <div class="left-container__drag-container__scrollable" 
                #scrollContainerLeft
                cdkDropList 
                [cdkDropListConnectedTo]="['dropField']"
                (scroll)="leftScrollBarScrolled($event)"
            >
                <ng-container *ngFor="let relationElement of quizRelations; let i = index">
                    <div class="draggable-element-container"
                    [ngClass]="{'pointer-none': checkAnswerTimer}"
                    cdkDrag (click)="leftDragableClick(relationElement, i)">
                        <img class="draggable-element-image" [src]="dragableImagePath+relationElement.dragableImage" alt="Bild">
                    </div>
                </ng-container>
            </div>
            <div class="left-container__drag-container__down-button"><div *ngIf="quizRelations.length > 3 && !isScrollbarLeftBottom" class="chevron-icon fas fa-chevron-down" (click)="clickScrollLeftDown()"></div></div>
        </div>
    </div>
    <div class="center-container">
        <div class="center-container__top">
            <div *ngIf="!isIntro" class="center-container__top__game-container">
                <div 
                    class="center-container__top__game-container__dropfield" 
                    id="dropField" 
                    cdkDropList 
                    (cdkDropListDropped)="drop($event)"
                >
                    <div class="center-container__top__game-container__droppedImage" *ngIf="quizRelationDropped != null" [@wrongAnswerAnimation]="wrongAnswerAnimationTrigger" [@correctAnswerAnimation]="dragableImagePath+quizRelationDropped.dragableImage">
                        <img [src]="dragableImagePath+quizRelationDropped.dragableImage" alt="Bild">
                    </div>
                    <p class="center-container__top-game__pull-image-here" *ngIf="!quizRelationDropped">{{'quiz:center-container__top-game__pull-image-here' | translate}}</p>
                </div>
            </div>
            <ng-container *ngIf="isIntro">
                <h1 class="center-container__top-intro__title">{{'quiz:center-container__top-intro__title' | translate}}</h1>
                <div class="center-container__top-intro__description">
                    <p class="center-container__top-intro__description__left-column">{{'quiz:center-container__top-intro__description__left-column' | translate}}</p>
                    <p class="center-container__top-intro__description__right-column">{{'quiz:center-container__top-intro__description__rigth-column' | translate}}</p>
                </div>
            </ng-container>
        </div>
        <div class="center-container__bottom" [ngClass]="{'opacityIfIntro': isIntro}">
            <div class="center-container__bottom__left-button" (click)="clickScrollBottomLeft()"><div class="chevron-icon fas fa-chevron-left"></div></div>
            <!--<div class="center-container__bottom_pageindex">{{getInfoIndexString()}}<span>/{{getInfoLengthString()}}</span></div>-->
            <div class="center-container__bottom_scroll-container" #scrollContainerBottom>
                <div class="center-container__bottom_scroll-container__element" [@fadeInOut]="bottomContainerSlideTo">
                    <div class="element-images">
                        <div class="element-image-container" *ngFor="let quizInfoImage of quizInfos[quizInfoIndex].infoImages; let k = index">
                            <img [src]="infoImagePath+quizInfoImage.image" alt="Bild" />
                            <div class="quiz-bottom-element-image-container-text"  *ngIf="quizInfoImage.titleLangString && quizInfoImage.titleLangString != ''" [innerHTML]="quizInfoImage.titleLangString | translate"></div>
                        </div>
                    </div>
                    <div class="quiz-element-text" [innerHTML]="quizInfos[quizInfoIndex].infoLangString | translate" ></div>
                </div>
            </div>
            <div class="center-container__bottom__right-button" (click)="clickScrollBottomRight()"><div class="chevron-icon fas fa-chevron-right"></div></div>
        </div>
    </div>
    <div class="right-container">
        <h1>{{'quiz:center-container__right-title' | translate}} {{" – "+quizRelationDone.length+" – "}}</h1>
        <div class="right-container__drag-container">
            <div class="right-container__drag-container__up-button"><div *ngIf="quizRelationDone.length > 2 && !isScrollbarRightTop" class="chevron-icon fas fa-chevron-up" (click)="clickScrollRightUp()"></div></div>
            <div class="right-container__drag-container__scrollable" #scrollContainerRight (scroll)="rightScrollBarScrolled($event)">
                <ng-container *ngFor="let relationElement of quizRelationDone; let i = index">
                    <div class="draggable-element-container">
                        <div class="draggable-element-container__done-element">
                            <img class="draggable-element-image" [src]="dragableImagePath+relationElement.dragableImage" alt="Bild">
                            <div class="draggable-element-text" [innerHTML]="relationElement.nameLangString | translate">{{relationElement.associatedInfoIndex}}</div>
                        </div>
                        
                    </div>
                </ng-container>
            </div>
            <div class="right-container__drag-container__down-button"><div *ngIf="quizRelationDone.length > 2 && !isScrollbarRightBottom" class="chevron-icon fas fa-chevron-down" (click)="clickScrollRightDown()"></div></div>
        </div>
    </div>
    <div class="button-confirm-counter" *ngIf="!isIntro">
        {{getInfoIndexString()}}
    </div>
    <div class="button-confirm-selection" *ngIf="!isIntro && answerState == QuizAnswerState.idle" (click)="clickCheckSelection();">
        <img class="button-img" src="assets/images/quiz/button-bestaetigen.png" />
    </div>
    <div class="button-confirm-selection selection-right" *ngIf="!isIntro && answerState == QuizAnswerState.right">
        <img class="line-img" src="assets/images/quiz/gruener-balken.png" />
        <img class="button-img" src="assets/images/quiz/button-korrekt.png" />
    </div>
    <div class="button-confirm-selection selection-wrong" *ngIf="!isIntro && answerState == QuizAnswerState.wrong">
        <img class="line-img" src="assets/images/quiz/roter-balken.png" />
        <img class="button-img" src="assets/images/quiz/button-falsch.png" />
    </div>
    <div class="introOverlay" *ngIf="isIntro" (click)="clickEndIntro()">
        <div class="finger-icon fas fa-hand-pointer"></div>
    </div>
    <div class="quizCompletedOverlay" *ngIf="isQuizCompleted">
        <div class="quizCompletedOverlay__text-container">
            <div class="quizCompletedOverlay__text">
                <h1 [innerHTML]="getResultTitle() | translate:quizLangParam"></h1>
                <p [innerHTML]="getResultText() | translate:quizLangParam"></p>
            </div>
        </div>
        <div class="resultApeImage ape-left-top"><img src="assets/images/quiz/resultScreen/affe-links-oben.png" /></div>
        <div class="resultApeImage ape-right-top"><img src="assets/images/quiz/resultScreen/affe-rechts-oben.png" /></div>
        <div class="resultApeImage ape-bottom-1"><img src="assets/images/quiz/resultScreen/affe-unten-1.png" /></div>
        <div class="resultApeImage ape-bottom-2"><img src="assets/images/quiz/resultScreen/affe-unten-2.png" /></div>
        <div class="resultApeImage ape-bottom-3"><img src="assets/images/quiz/resultScreen/affe-unten-3.png" /></div>
        <div class="resultApeImage ape-bottom-4"><img src="assets/images/quiz/resultScreen/affe-unten-4.png" /></div>
        <div class="resultApeImage ape-bottom-5"><img src="assets/images/quiz/resultScreen/affe-unten-5.png" /></div>
    </div>
    <div class="button-reset-quiz" *ngIf="!isIntro || isQuizCompleted" (click)="clickResetQuiz();">
        <img class="button-img" src="assets/images/quiz/buton-neu.png" />
    </div>
    <app-bottom-bar [backButton]="true" [infoText]="{text: 'quiz:bottom-bar__info-popup__text' | translate}"></app-bottom-bar>
</div>
