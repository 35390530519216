import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { Subject, take, takeUntil } from 'rxjs';
import { CameraStreamComponent } from 'src/app/components/camera-stream/camera-stream.component';
import { PopupContent } from 'src/app/interfaces/common';

@Component({
  selector: 'app-live-cam',
  templateUrl: './live-cam.component.html',
  styleUrls: ['./live-cam.component.scss']
})
export class LiveCamComponent implements OnInit {

  @ViewChild('cameraStream') cameraStream:CameraStreamComponent | null = null;
  private readonly destroyed$ = new Subject<void>();
  popupContent: PopupContent | undefined;
  isCamOpen = false;

  constructor() { }

  ngOnInit(): void {
  }

  toggleCam(): void{
    this.isCamOpen = !this.isCamOpen;
    if(this.cameraStream){
      if(this.isCamOpen)
        this.cameraStream.pauseVideo();
      else
        this.cameraStream.playVideo();
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

}
